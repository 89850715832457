<template>
  <v-container class="container_hello">
    <v-row class="respon-flex" style="padding: 24px">
      <v-col cols="12">
        <h1 color="secondary">{{ powerAmp }}</h1>
      </v-col>
      <v-row d-flex v-if="checkData() == false">
        <v-col
          class="text-center"
          cols="12"
          lg="6"
          v-for="item in data"
          :key="item.name"
        >
          <a :href="`/product/powerAmp/${item.url}`">
            <v-img :src="require('../../assets/' + item.img)" contain />
          </a>

          <br />
          <h1 style="color: black !important">{{ item.name }}</h1>
        </v-col>
      </v-row>

      <v-row d-flex v-if="checkData() == true">
        <v-col class="responsive-fix text-center" cols="12" lg="7">
          <v-carousel
            eager
            v-if="this.productData !== null"
            cycle
            height="500"
            interval="6000"
            hide-delimiters
            show-arrows-on-hover
            rel="preload"
          >
            <v-carousel-item
              v-for="(item, i) in this.productData.img"
              :key="i"
              :src="require('../../assets/' + item)"
            ></v-carousel-item>
          </v-carousel>
          <br />
          <p>{{ productData.desc }}</p>

          <br />
        </v-col>
        <v-col cols="12" lg="5" style="padding: 0px !important">
          <v-simple-table v-if="this.productData !== null">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>SPECIFICATION</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>FREQUENCY RESPONSE</td>
                  <td>{{ productData.frequency }}</td>
                </tr>
                <tr>
                  <td>INPUT IMPEDANCE</td>
                  <td>{{ productData.input }}</td>
                </tr>
                <tr>
                  <td>DAMPING FACTOR @ 1KHz</td>
                  <td>{{ productData.damping }}</td>
                </tr>
                <tr>
                  <td>CROSSTALK</td>
                  <td>{{ productData.crosstalk }}</td>
                </tr>
                <tr>
                  <td>SIGNAL/NOISE RATIO</td>
                  <td>{{ productData.signal }}</td>
                </tr>
                <tr>
                  <td>ELECTRONIC X-OVER LOW PASS</td>
                  <td>{{ productData.electronicLow }}</td>
                </tr>
                <tr>
                  <td>ELECTRONIC X-OVER HIGH PASS</td>
                  <td>{{ productData.electronicHigh }}</td>
                </tr>
                <tr>
                  <td>INPUT SENSITIVITY</td>
                  <td>{{ productData.inputSensitive }}</td>
                </tr>
              </tbody>

              <thead>
                <tr>
                  <th>OUTPUT POWER</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="font-weight: 500;">Load Resistance</th>
                  <th style="font-weight: 500;">STEREO</th>
                  <th style="font-weight: 500;">BRIDGE MONO</th>
                </tr>
                <tr>
                  <td>2Ω</td>
                  <td>{{ productData.stereo2ohm }}</td> 
                  <td>{{ productData.bridge4ohm }}</td> 
                </tr>
                <tr>
                  <td>4Ω</td>
                  <td>{{ productData.stereo4ohm }}</td> 
                  <td>{{ productData.bridge4ohm }}</td> 
                </tr>
                <tr>
                  <td>8Ω</td>
                  <td>{{ productData.stereo8ohm }}</td> 
                  <td>{{ productData.bridge8ohm }}</td> 
                </tr>
                <tr>
                  <td>16Ω</td>
                  <td>-</td> 
                  <td>{{ productData.bridge16ohm }}</td> 
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.container_hello {
  max-width: 1200px !important;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
}
.responsive-fix {
  .v-responsive__content {
    width: auto !important;
  }
  // .v-image__image--cover {
  //   background-size: contain !important;
  // }
}

.respon-flex {
  @media screen and (max-width: 1263px) {
     display: inline !important;
     }
     display: flex;
  }

.v-responsive {
  flex: none !important;
}
</style>
<script>
import json from "./PowerAmp-data.json";
import router from "@/router";
export default {
  name: "PowerAmp",
  created() {
    this.setData();
  },
  data: () => ({
    productData: null,
    powerAmp: "Power Amplifier",
    data: json,
    nowData: "",
    haveData: false,
  }),
  methods: {
    setData() {
      document.title = "Power Amplifier - ORBIT., LTD. PART";
      json.forEach((data) => {
        if (this.$route.params.id == null) return;
        if (this.$route.params.id == data.url) {
          this.haveData = true;
          this.powerAmp = data.name;
          document.title =
            this.powerAmp + " - Power Amplifier - ORBIT., LTD. PART";

          const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          };
          console.log(this.$route.params.id);
          fetch(
            `http://192.168.1.195:8080/api/getPowerAmp?id=${this.$route.params.id}`,
            requestOptions
          ).then((r) => {
            if (r.status !== 200) {
              router.push({ name: "NotFound" });
              // this.$router.replace('/product/welding')
              return;
            }
            r.json().then((json) => {
              this.productData = json;
              console.log(json);
            });
          });
          this.nowData = data;
          return;
        }
      });
    },

    getImg() {
      console.log(this.nowData.img);
      return this.nowData.img;
    },
    checkData() {
      return this.haveData;
    },
    selectProduct(url) {
      this.$router.replace("/product/poweramp/" + url);
    },
  },
};
</script>
